var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "activity",
    attrs: {
      "refs": "activity"
    }
  }, [_c('div', {
    staticClass: "tags-wrapper y"
  }, [_vm._m(0), _c('div', {
    staticClass: "body"
  }, _vm._l(_vm.tags, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "tag",
      class: {
        active: _vm.queryData.activityId === item.id
      }
    }, [_c('div', {
      staticClass: "x5",
      on: {
        "click": function click($event) {
          return _vm.tagSwitch(item);
        }
      }
    }, [_c('svg-icon', {
      staticClass: "tag-icon",
      attrs: {
        "icon-class": "tag"
      }
    }), _c('div', {
      staticClass: "name-wrap"
    }, [_c('h4', [_vm._v(_vm._s(item.name))]), _c('p', [_vm._v(_vm._s(item.inDate))])])], 1), false ? _c('el-dropdown', {
      attrs: {
        "trigger": "click"
      }
    }, [_c('div', [_c('svg-icon', {
      staticClass: "more-icon",
      attrs: {
        "icon-class": "gengduo"
      }
    })], 1), _c('el-dropdown-menu', {
      attrs: {
        "slot": "dropdown"
      },
      slot: "dropdown"
    }, [_c('el-dropdown-item', {
      nativeOn: {
        "click": function click($event) {
          return _vm.showEditTagDialog(item);
        }
      }
    }, [_vm._v("编辑")]), _c('el-dropdown-item', {
      nativeOn: {
        "click": function click($event) {
          return _vm.delTag(item.id);
        }
      }
    }, [_vm._v("删除")])], 1)], 1) : _vm._e()], 1);
  }), 0), false ? _c('div', {
    staticClass: "foot center"
  }, [_c('div', {
    staticClass: "add-btn",
    on: {
      "click": function click($event) {
        _vm.toggleTagDialog = true;
      }
    }
  }, [_c('svg-icon', {
    staticClass: "icon",
    attrs: {
      "icon-class": "add"
    }
  }), _c('span', [_vm._v("新增标签")])], 1)]) : _vm._e()]), _c('div', {
    staticClass: "comment-wrapper"
  }, [_vm.queryData.activityId ? _c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('h3', [_vm._v(" " + _vm._s(_vm.curTag.name) + " ")]), _c('p', [_vm._v("创建时间：" + _vm._s(_vm.curTag.inDate))])]), _c('div', {
    staticClass: "operator x3"
  }, [_c('div', {
    staticClass: "l"
  }, [_c('el-input', {
    staticClass: "f-search-bar",
    staticStyle: {
      "width": "240px"
    },
    attrs: {
      "placeholder": "昵称、电话、公司"
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.queryData.customerName,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "customerName", $$v);
      },
      expression: "queryData.customerName"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append"
    },
    on: {
      "click": _vm.search
    },
    slot: "append"
  }, [_vm._v("搜索")])], 1), _c('el-date-picker', {
    staticClass: "ml20",
    attrs: {
      "type": "date",
      "value-format": "yyyy-MM-dd",
      "placeholder": "发布日期"
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.publishDate,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "publishDate", $$v);
      },
      expression: "queryData.publishDate"
    }
  })], 1), _c('div', {
    staticClass: "tabs-head"
  }, _vm._l(_vm.tabs, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      class: {
        active: item.value === _vm.queryData.orderType
      },
      on: {
        "click": function click($event) {
          return _vm.tabClick(item);
        }
      }
    }, [item.showIcon ? _c('img', {
      attrs: {
        "src": require("../../../../assets/icon_gift.png")
      }
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(item.label))])]);
  }), 0)])]) : _vm._e(), _vm.queryData.activityId ? _c('div', {
    staticClass: "comment-list"
  }, _vm._l(_vm.commentList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "comment-item",
      style: {
        background: item.commentContentTheme
      }
    }, [_c('div', {
      staticClass: "head x6"
    }, [_c('div', {
      staticClass: "x5"
    }, [_c('el-image', {
      staticClass: "avatar",
      attrs: {
        "src": item.customerAvatar
      }
    }), _c('div', {
      staticClass: "name-wrap y3"
    }, [_c('h3', [_vm._v(_vm._s(item.customerName))]), _c('p', [_vm._v(" " + _vm._s(_vm._f("formatPhone")(item.contactPhone)) + _vm._s(item.companyName ? "," + item.companyName : "") + " ")])])], 1), _c('div', {
      staticClass: "date"
    }, [_vm._v(_vm._s(item.commentDate))])]), _c('div', {
      staticClass: "body"
    }, [_vm._v(" " + _vm._s(item.commentContent) + " ")]), _c('div', {
      staticClass: "foot x6"
    }, [_c('div', {
      staticClass: "x5"
    }, [_c('div', {
      staticClass: "zan"
    }, [_vm._v("点赞（" + _vm._s(item.praiseCount) + "）")]), item.prizeName ? _c('div', {
      staticClass: "awards x5"
    }, [_c('div', {
      staticClass: "x5 sub"
    }, [_c('img', {
      attrs: {
        "src": require("../../../../assets/icon_gift.png")
      }
    }), _c('p', [_vm._v(_vm._s(item.prizeName))])]), _c('el-dropdown', {
      attrs: {
        "trigger": "click"
      }
    }, [_c('div', {
      staticClass: "dropdown-icon xyc"
    }, [_c('i', {
      staticClass: "el-icon-caret-bottom"
    })]), _c('el-dropdown-menu', {
      attrs: {
        "slot": "dropdown"
      },
      slot: "dropdown"
    }, [_c('el-dropdown-item', {
      nativeOn: {
        "click": function click($event) {
          return _vm.cancelGift(item, index);
        }
      }
    }, [_vm._v("取消中奖")]), _c('el-dropdown-item', {
      attrs: {
        "disabled": item.isPushed
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.sendMessage(item, index);
        }
      }
    }, [_vm._v(_vm._s(item.isPushed ? "已" : "") + "推送消息")])], 1)], 1)], 1) : _vm._e()]), _c('div', {
      staticClass: "operator"
    }, [_c('el-button', {
      attrs: {
        "type": "text"
      },
      on: {
        "click": function click($event) {
          return _vm.delComment(item);
        }
      }
    }, [_vm._v("删除")]), !item.prizeName ? _c('el-dropdown', {
      ref: "winGiftDropdown",
      refInFor: true,
      staticClass: "ml20",
      attrs: {
        "trigger": "click"
      }
    }, [_c('el-button', {
      attrs: {
        "type": "text"
      }
    }, [_vm._v("中奖")]), _c('el-dropdown-menu', {
      attrs: {
        "slot": "dropdown"
      },
      slot: "dropdown"
    }, [_c('div', {
      staticClass: "win-gift"
    }, [_c('div', {
      staticClass: "title"
    }, [_vm._v("请选择奖品")]), _c('div', {
      staticClass: "list"
    }, [_vm._l(_vm.giftList, function (item2, index2) {
      return _c('div', {
        key: index2,
        staticClass: "item x5",
        class: {
          active: item2.id === item.prizeId
        },
        on: {
          "click": function click($event) {
            return _vm.chooseGift(item, item2, index);
          }
        }
      }, [_c('img', {
        attrs: {
          "src": require("../../../../assets/icon_gift.png")
        }
      }), _c('p', [_vm._v(_vm._s(item2.name))])]);
    }), _c('div', {
      staticClass: "item x5 add",
      on: {
        "click": function click($event) {
          _vm.toggleManageGiftDialog = true;
        }
      }
    }, [_c('i', {
      staticClass: "el-icon-present"
    }), _c('p', [_vm._v("管理奖品")])])], 2), _c('div', {
      staticClass: "foot"
    }, [_c('el-button', {
      attrs: {
        "size": "mini"
      },
      on: {
        "click": function click($event) {
          return _vm.cancelWinDropdown(index);
        }
      }
    }, [_vm._v("取消")]), _c('el-button', {
      attrs: {
        "type": "primary",
        "size": "mini"
      },
      on: {
        "click": function click($event) {
          return _vm.winGift(item, index);
        }
      }
    }, [_vm._v("确定")])], 1)])])], 1) : _vm._e()], 1)])]);
  }), 0) : _vm._e(), _vm.queryData.activityId ? _c('el-pagination', {
    attrs: {
      "current-page": _vm.queryData.pageNum,
      "page-sizes": _vm.pageSizes,
      "page-size": _vm.queryData.pageSize,
      "layout": "sizes, prev, pager, next,jumper",
      "total": _vm.commentTotal
    },
    on: {
      "size-change": _vm.pageSizeChange,
      "current-change": _vm.pageNumChange,
      "update:currentPage": function updateCurrentPage($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:current-page": function updateCurrentPage($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      }
    }
  }) : _vm._e()], 1), _c('el-dialog', {
    staticClass: "f-dialog",
    attrs: {
      "title": "管理商品",
      "visible": _vm.toggleManageGiftDialog,
      "width": "600px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleManageGiftDialog = $event;
      }
    }
  }, [_c('div', {
    staticClass: "activity-git-list"
  }, _vm._l(_vm.giftList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item x5"
    }, [_c('img', {
      attrs: {
        "src": require("../../../../assets/icon_gift.png")
      }
    }), _c('p', [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "operator"
    }, [_c('el-dropdown', {
      ref: "editGiftDropdown",
      refInFor: true,
      attrs: {
        "placement": "bottom-start",
        "trigger": "click"
      }
    }, [_c('el-button', {
      attrs: {
        "type": "text"
      },
      on: {
        "click": function click($event) {
          return _vm.showEditGift(item);
        }
      }
    }, [_vm._v("编辑")]), _c('el-dropdown-menu', {
      attrs: {
        "slot": "dropdown"
      },
      slot: "dropdown"
    }, [_c('div', {
      staticClass: "edit-git-wrap x5"
    }, [_c('el-input', {
      attrs: {
        "size": "small",
        "placeholder": "请输入奖品名称，15字以内"
      },
      model: {
        value: _vm.giftFormData.name,
        callback: function callback($$v) {
          _vm.$set(_vm.giftFormData, "name", $$v);
        },
        expression: "giftFormData.name"
      }
    }), _c('el-button', {
      staticClass: "ml10",
      attrs: {
        "size": "small"
      },
      on: {
        "click": function click($event) {
          return _vm.cancelEditGiftDropdown(index);
        }
      }
    }, [_vm._v("取消")]), _c('el-button', {
      attrs: {
        "size": "small",
        "type": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.editGift(index);
        }
      }
    }, [_vm._v("确定")])], 1)])], 1), !item.relatedComment ? _c('el-button', {
      staticClass: "ml10",
      attrs: {
        "type": "text"
      },
      on: {
        "click": function click($event) {
          return _vm.delGift(item);
        }
      }
    }, [_vm._v("删除")]) : _vm._e()], 1)]);
  }), 0), _c('div', {
    staticClass: "add-gift"
  }, [_c('el-dropdown', {
    ref: "addGiftDropdown",
    attrs: {
      "placement": "bottom-start",
      "trigger": "click"
    }
  }, [_c('el-button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": _vm.showAddGift
    }
  }, [_c('i', {
    staticClass: "el-icon-plus"
  }), _vm._v("添加奖品")]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('div', {
    staticClass: "edit-git-wrap x5"
  }, [_c('el-input', {
    attrs: {
      "size": "small",
      "placeholder": "请输入奖品名称，15字以内",
      "maxlength": "15"
    },
    model: {
      value: _vm.giftFormData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.giftFormData, "name", $$v);
      },
      expression: "giftFormData.name"
    }
  }), _c('el-button', {
    staticClass: "ml10",
    attrs: {
      "size": "small"
    },
    on: {
      "click": _vm.cancelAddGiftDropdown
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "size": "small",
      "type": "primary"
    },
    on: {
      "click": _vm.addGift
    }
  }, [_vm._v("确定")])], 1)])], 1)], 1)])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-mol"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("主题")]), _c('p', {
    staticClass: "summary"
  }, [_vm._v("支持活动、楼盘等主题下的评论")])])]);

}]

export { render, staticRenderFns }