<template>
  <div class="activity" refs="activity">
    <div class="tags-wrapper y">
      <div class="header-mol">
        <div class="info">
          <h3 class="title">主题</h3>
          <p class="summary">支持活动、楼盘等主题下的评论</p>
        </div>
      </div>
      <div class="body">
        <div
          class="tag"
          v-for="(item, index) in tags"
          :key="index"
          :class="{ active: queryData.activityId === item.id }"
        >
          <div class="x5" @click="tagSwitch(item)">
            <svg-icon icon-class="tag" class="tag-icon"></svg-icon>
            <div class="name-wrap">
              <h4>{{ item.name }}</h4>
              <p>{{ item.inDate }}</p>
            </div>
          </div>
          <el-dropdown trigger="click" v-if="false">
            <div>
              <svg-icon icon-class="gengduo" class="more-icon"></svg-icon>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="showEditTagDialog(item)"
                >编辑</el-dropdown-item
              >
              <el-dropdown-item @click.native="delTag(item.id)"
                >删除</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="foot center" v-if="false">
        <div class="add-btn" @click="toggleTagDialog = true">
          <svg-icon icon-class="add" class="icon"></svg-icon>
          <span>新增标签</span>
        </div>
      </div>
    </div>
    <div class="comment-wrapper">
      <div class="head" v-if="queryData.activityId">
        <div class="title">
          <h3>
            {{ curTag.name }}
          </h3>
          <p>创建时间：{{ curTag.inDate }}</p>
        </div>
        <div class="operator x3">
          <div class="l">
            <el-input
              placeholder="昵称、电话、公司"
              v-model="queryData.customerName"
              @keyup.enter.native="search"
              style="width: 240px;"
              class="f-search-bar"
            >
              <el-button slot="append" @click="search">搜索</el-button>
            </el-input>

            <el-date-picker
              class="ml20"
              v-model="queryData.publishDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="发布日期"
              @change="search"
            >
            </el-date-picker>
          </div>
          <div class="tabs-head">
            <div
              class="item"
              :class="{ active: item.value === queryData.orderType }"
              v-for="(item, index) in tabs"
              :key="index"
              @click="tabClick(item)"
            >
              <img
                src="../../../../assets/icon_gift.png"
                v-if="item.showIcon"
              />
              <span>{{ item.label }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="comment-list" v-if="queryData.activityId">
        <div
          class="comment-item"
          v-for="(item, index) in commentList"
          :key="index"
          :style="{ background: item.commentContentTheme }"
        >
          <div class="head x6">
            <div class="x5">
              <el-image class="avatar" :src="item.customerAvatar"></el-image>
              <div class="name-wrap y3">
                <h3>{{ item.customerName }}</h3>
                <p>
                  {{ item.contactPhone | formatPhone
                  }}{{ item.companyName ? "," + item.companyName : "" }}
                </p>
              </div>
            </div>
            <div class="date">{{ item.commentDate }}</div>
          </div>
          <div class="body">
            {{ item.commentContent }}
          </div>
          <div class="foot x6">
            <div class="x5">
              <div class="zan">点赞（{{ item.praiseCount }}）</div>
              <div class="awards x5" v-if="item.prizeName">
                <div class="x5 sub">
                  <img src="../../../../assets/icon_gift.png" />
                  <p>{{ item.prizeName }}</p>
                </div>
                <el-dropdown trigger="click">
                  <div class="dropdown-icon xyc">
                    <i class="el-icon-caret-bottom"></i>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="cancelGift(item, index)"
                      >取消中奖</el-dropdown-item
                    >
                    <el-dropdown-item
                      :disabled="item.isPushed"
                      @click.native="sendMessage(item, index)"
                      >{{ item.isPushed ? "已" : "" }}推送消息</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div class="operator">
              <el-button type="text" @click="delComment(item)">删除</el-button>
              <el-dropdown
                ref="winGiftDropdown"
                class="ml20"
                trigger="click"
                v-if="!item.prizeName"
              >
                <el-button type="text">中奖</el-button>
                <el-dropdown-menu slot="dropdown">
                  <div class="win-gift">
                    <div class="title">请选择奖品</div>
                    <div class="list">
                      <div
                        class="item x5"
                        :class="{
                          active: item2.id === item.prizeId
                        }"
                        v-for="(item2, index2) in giftList"
                        :key="index2"
                        @click="chooseGift(item, item2, index)"
                      >
                        <img src="../../../../assets/icon_gift.png" />
                        <p>{{ item2.name }}</p>
                      </div>
                      <div
                        class="item x5 add"
                        @click="toggleManageGiftDialog = true"
                      >
                        <i class="el-icon-present"></i>
                        <p>管理奖品</p>
                      </div>
                    </div>
                    <div class="foot">
                      <el-button size="mini" @click="cancelWinDropdown(index)"
                        >取消</el-button
                      >
                      <el-button
                        type="primary"
                        size="mini"
                        @click="winGift(item, index)"
                        >确定</el-button
                      >
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        v-if="queryData.activityId"
        @size-change="pageSizeChange"
        @current-change="pageNumChange"
        :current-page.sync="queryData.pageNum"
        :page-sizes="pageSizes"
        :page-size="queryData.pageSize"
        layout="sizes, prev, pager, next,jumper"
        :total="commentTotal"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="管理商品"
      class="f-dialog"
      :visible.sync="toggleManageGiftDialog"
      width="600px"
    >
      <div class="activity-git-list">
        <div class="item x5" v-for="(item, index) in giftList" :key="index">
          <img src="../../../../assets/icon_gift.png" />
          <p>{{ item.name }}</p>
          <div class="operator">
            <el-dropdown
              placement="bottom-start"
              trigger="click"
              ref="editGiftDropdown"
            >
              <el-button type="text" @click="showEditGift(item)"
                >编辑</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <div class="edit-git-wrap x5">
                  <el-input
                    size="small"
                    placeholder="请输入奖品名称，15字以内"
                    v-model="giftFormData.name"
                  ></el-input>
                  <el-button
                    class="ml10"
                    size="small"
                    @click="cancelEditGiftDropdown(index)"
                    >取消</el-button
                  >
                  <el-button
                    size="small"
                    type="primary"
                    @click="editGift(index)"
                    >确定</el-button
                  >
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              class="ml10"
              v-if="!item.relatedComment"
              type="text"
              @click="delGift(item)"
              >删除</el-button
            >
          </div>
        </div>
      </div>
      <div class="add-gift">
        <el-dropdown
          placement="bottom-start"
          ref="addGiftDropdown"
          trigger="click"
        >
          <el-button type="text" @click="showAddGift"
            ><i class="el-icon-plus"></i>添加奖品</el-button
          >
          <el-dropdown-menu slot="dropdown">
            <div class="edit-git-wrap x5">
              <el-input
                size="small"
                placeholder="请输入奖品名称，15字以内"
                maxlength="15"
                v-model="giftFormData.name"
              ></el-input>
              <el-button
                class="ml10"
                size="small"
                @click="cancelAddGiftDropdown"
                >取消</el-button
              >
              <el-button size="small" type="primary" @click="addGift"
                >确定</el-button
              >
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getActivityList,
  getWebCommentPage,
  getActivityPrizeList,
  addPrize,
  commentGetPrize,
  deletePrize,
  editPrize,
  pushActivityPrizeMessage,
  commentCancelPrize,
  deleteComment
} from "@/api/activity";
export default {
  data() {
    return {
      toggleChooseEstateDialog: false,
      toggleManageGiftDialog: false,
      queryData: {
        activityId: null,
        customerName: "",
        orderType: 1,
        pageNum: 1,
        pageSize: 10,
        publishDate: ""
      },
      pageSizes: [10, 20, 30, 50],
      tags: [], //活动列表
      tabs: [
        { label: "最新发布", value: 1 },
        { label: "点赞最多", value: 2 },
        { label: "获奖评论", value: 3, showIcon: true }
      ],
      giftFormData: {
        //编辑 新增奖品数据
        id: null,
        name: ""
      },
      curTag: {},
      giftList: [], //奖品列表
      giftActiveList: [],
      commentTotal: 0,
      commentList: [] //评论列表
    };
  },
  created() {
    this.getActivityList();
  },
  methods: {
    pageSizeChange(num) {
      this.queryData.pageSize = num;
      this.getWebCommentPage();
    },
    pageNumChange(num) {
      this.queryData.pageNum = num;
      this.getWebCommentPage();
    },
    search() {
      //查询
      this.queryData.pageNum = 1;
      this.getWebCommentPage();
    },
    async getActivityPrizeList() {
      //查询活动奖品列表
      const res = await getActivityPrizeList({ id: this.queryData.activityId });
      if (res) {
        this.giftList = res;
      }
    },
    async getActivityList() {
      //获取活动列表
      const res = await getActivityList();
      if (res) {
        this.tags = res;
        this.queryData.activityId = res[0].id;
        this.curTag = res[0];
        this.getWebCommentPage();
        this.getActivityPrizeList();
      }
    },
    tagSwitch(item) {
      this.curTag = item;
      this.queryData.activityId = item.id;
    },
    async getWebCommentPage() {
      //获取活动评论列表
      const res = await getWebCommentPage(this.queryData);
      if (res) {
        this.commentList = res.list;
        this.commentTotal = res.total;
      }
    },
    tabClick(item) {
      //点击tab
      this.queryData.orderType = item.value;
      this.queryData.pageNum = 1;
      this.getWebCommentPage();
    },
    chooseGift(item, item2, index) {
      //选择奖品
      this.$set(this.commentList, index, { ...item, prizeId: item2.id });
    },
    async winGift(item, index) {
      //获奖
      const res = await commentGetPrize({
        activityId: this.queryData.activityId,
        commentId: item.id,
        prizeId: item.prizeId
      });
      if (res) {
        this.$showSuccess("操作成功");
        this.getWebCommentPage();
      } else {
        this.$set(this.commentList, index, { ...item, prizeId: null });
      }
      this.cancelWinDropdown(index);
    },
    cancelWinDropdown(index) {
      //关闭中奖下拉框
      this.$refs.winGiftDropdown[index].hide();
    },
    cancelEditGiftDropdown(index) {
      //关闭编辑奖品下拉框
      this.$refs.editGiftDropdown[index].hide();
    },
    showAddGift() {
      this.giftFormData = { name: "" };
    },
    showEditGift(item) {
      this.giftFormData = { ...item };
    },
    validateGiftForm() {
      if (!this.giftFormData.name.trim()) {
        this.$showError("请输入奖品名");
        return false;
      }
      return true;
    },
    delComment({ id }) {
      //删除评论
      this.$showConfirm(async () => {
        const res = await deleteComment({ id });
        if (res) {
          this.$showSuccess("删除成功");
          this.getWebCommentPage();
        }
      }, "是否确认删除该评论?");
    },
    async editGift(index) {
      //编辑奖品
      if (!this.validateGiftForm()) return;
      const res = await editPrize({
        ...this.giftFormData,
        activityId: this.queryData.activityId
      });
      if (res) {
        this.$showSuccess("添加成功");
        this.getActivityPrizeList();
      }
      this.cancelEditGiftDropdown(index);
    },
    async addGift() {
      //添加奖品
      if (!this.validateGiftForm()) return;
      const res = await addPrize({
        ...this.giftFormData,
        activityId: this.queryData.activityId
      });
      if (res) {
        this.$showSuccess("添加成功");
        this.getActivityPrizeList();
      }
      this.cancelAddGiftDropdown();
    },
    async delGift({ id }) {
      //删除奖品
      const res = await deletePrize({ id });
      if (res) {
        this.$showSuccess("删除成功");
        this.getActivityPrizeList();
      }
    },
    cancelAddGiftDropdown() {
      //关闭添加礼品弹出层
      this.$refs.addGiftDropdown.hide();
    },
    async cancelGift(item) {
      //取消中奖
      const res = await commentCancelPrize({
        activityId: this.queryData.activityId,
        commentId: item.id,
        prizeId: item.prizeId
      });
      if (res) {
        this.getWebCommentPage();
        this.getActivityPrizeList();
      }
    },
    async sendMessage(item, index) {
      //推送消息
      const res = await pushActivityPrizeMessage({
        activityId: this.queryData.activityId,
        commentId: item.id,
        prizeId: item.prizeId
      });
      if (res) {
        this.$showSuccess("操作成功");
        this.$set(this.commentList, index, { ...item, isPushed: true });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.el-pagination {
  padding-top: 32px;
  padding-bottom: 0;
}
.ml20 {
  margin-left: 20px;
}
.ml10 {
  margin-left: 10px;
}
.edit-git-wrap {
  padding: 0 10px;
}
.activity-git-list {
  display: grid;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-template-columns: repeat(2, 270px);
  .item {
    padding: 0 20px;
    width: 270px;
    height: 80px;
    position: relative;
    background: #eee;
    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      margin-right: 20px;
    }
    .operator {
      position: absolute;
      right: 20px;
      top: 0;
    }
  }
}
.add-gift {
  margin-top: 20px;
}
.win-gift {
  width: 180px;
  background: #fff;
  .title {
    padding: 0 20px;
    line-height: 40px;
    color: #999;
  }
  .list {
    .item {
      padding: 0 20px;
      height: 34px;
      border-top: 1px solid #eee;
      &.active {
        color: $blue;
        background: $blueBg;
        border-top: 0;
        position: relative;
        &:after {
          font-family: element-icons;
          content: "\e6da";
          color: $blue;
          display: block;
          position: absolute;
          right: 20px;
          top: 5px;
          font-size: 20px;
        }
      }
      img,
      i {
        width: 16px;
        height: 16px;
        font-size: 16px;
        object-fit: cover;
        margin-right: 10px;
      }
      p {
        max-width: 8em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &.add {
        color: #999;
      }
      &:hover {
        cursor: pointer;
        color: $blue;
      }
    }
  }
  .foot {
    padding: 10px 20px 0;
    margin-top: 20px;
    text-align: right;
    border-top: 1px solid #eee;
  }
}
.activity {
  position: relative;
  height: 100%;
  display: flex;
  .header-mol {
    padding: 10px;
  }
}
.comment-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px;
  margin-left: 20px;
  background: #fff;
  box-shadow: 0 0 5px #eee;
  & > .head {
    .title {
      h3 {
        font-size: 16px;
        line-break: 16px;
        padding-left: 20px;
        border-left: 3px solid $primary;
      }
      p {
        margin-top: 10px;
        padding-left: 20px;
        color: $color9;
      }
    }
    .operator {
      padding-top: 30px;
    }
  }
}

.tabs-head {
  display: flex;
  height: 40px;
  border: 5px solid #eee;
  background: #eee;
  border-radius: 20px;
  .item {
    padding: 0 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.active {
      background: #fff;
      border-radius: 15px;
    }
    img {
      width: 15px;
      height: 15px;
      margin-right: 10px;
      object-fit: cover;
    }
  }
}
.comment-list {
  flex: 1;
  overflow-y: auto;
  padding: 0 15%;
  padding-top: 10px;
  margin-top: 20px;
  .comment-item {
    padding: 15px 30px 30px 30px;
    background: #eee;
    margin-bottom: 20px;
    border-radius: 5px;
    .head {
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .name-wrap {
        padding-left: 10px;
        p {
          color: #999;
        }
      }
      .date {
        color: #999;
      }
    }
    .body {
      padding: 15px 0;
      line-height: 1.5;
    }
    .foot {
      .zan {
        color: #666;
      }
      .awards {
        margin-left: 50px;
        height: 30px;
        .sub {
          color: $red;
          height: 30px;
          margin-right: 10px;
          padding: 0 15px;
          background: #fff;
          border-radius: 5px;
          img {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            object-fit: cover;
          }
          p {
            max-width: 8em;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        .dropdown-icon {
          color: #999;
          cursor: pointer;
          height: 30px;
          padding: 0 15px;
          background: #fff;
          border-radius: 5px;
        }
      }
    }
  }
}
.tags-wrapper {
  width: 280px;
  min-width: 280px;
  padding: 0 15px;
  background: #fff;
  box-shadow: 0 0 5px #eee;
  .head {
    padding: 30px 0;
    h3 {
      font-size: 16px;
    }
    p {
      margin-top: 10px;
      color: $color9;
    }
  }
  .body {
    flex: 1;
    .tag {
      padding: 0 15px;
      display: flex;
      height: 60px;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      & > .x5 {
        width: 180px;
      }
      &.active {
        background: $blueBg;
        color: $blue;
        .name-wrap {
          p {
            color: $blue;
          }
        }
        .tag-icon {
          color: $blue;
        }
      }
      .name-wrap {
        p {
          color: #666;
        }
      }
      .tag-icon {
        font-size: 20px;
        margin-right: 10px;
        color: #666;
      }
      .more-icon {
        padding-left: 5px;
        font-size: 20px;
        color: #333;
        cursor: pointer;
      }
    }
  }
  .foot {
    padding: 30px 0;
    .add-btn {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 20px;
      background: $blue;
      cursor: pointer;
      transition: width 0.1s ease-in-out;
      white-space: nowrap;
      &:hover {
        width: 115px;
      }
      &:active {
        opacity: 0.7;
      }
      .icon,
      span {
        display: inline-block;
        vertical-align: top;
      }
      .icon {
        width: 20px;
        height: 20px;
        margin: 10px;
        color: #fff;
        font-size: 20px;
      }
      span {
        line-height: 40px;
        color: #fff;
        padding-right: 10px;
      }
    }
  }
}
</style>
